module.exports = {
  en: {
    path: '',
    locale: 'English',
    default: true,
  },
  zh: {
    path: 'zh',
    locale: '中文',
  },
};
